

@font-face {
    font-family: 'Ghwduk5';
    /* src: url('Font/ghwduk_5.ttf') format('truetype'); */
    font-weight: normal;
    font-style: normal;
}

.Genius::before {
    content: "Vijayvir Singh ";
    display: inline-block;
}

.Genius:hover::before {
    content: "ਵਿਜੇਵੀਰ ਸਿੰਘ ";
    font-family: 'Ghwduk5', sans-serif;
}




body {
    font-family: 'Merriweather', serif;

    margin: 0;
    padding: 0;
}

header {

    padding: 1.5rem 0;
    text-align: center;

    top: 0;
    z-index: 1000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.site-title {
    font-family: 'Merriweather', serif;
    font-size: 2.5rem;
    margin: 0;
}

.site-subtitle {
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
    margin: 0.5rem 0 0;
    letter-spacing: 0.1rem;
}

.navbar {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 1rem 0;
    background-color: #1a1a1a;
}

.navbar li {
    margin: 0 1.5rem;
}

.navbar li a {
    color: #00FF00;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
}

.navbar li a:hover {
    color: #c70039;
}

.background-content{
    
    padding: 2rem 1rem;
    text-align: center;

    margin-bottom:2em;
    background: rgba(0, 0, 0, 0.3); /* Semi-transparent black */
    backdrop-filter: blur(10px); /* Glass effect: Blur background behind */
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 255, 0, 0.3); /* Neon green shadow */
    border: 1px solid rgba(0, 255, 0, 0.2); /* Neon green border */
    color: #00FF00; /* Hacker green text color */
    font-family: 'Courier New', monospace; /* Hacker-like font */

}


.headline-title {
    font-size: 2rem;
    font-weight: 700;

}

.headline-content {
    font-size: 1.25rem;
    color: #00FF00;
    margin-top: 1rem;
}

.content-section {
    padding: 3rem 1rem;
}
blockquote {
    font-family: 'Georgia', serif; /* You can change this to your preferred font */
    font-size: 1.5em; /* Adjust the size as needed */
    font-style: italic; /* Italic for emphasis */
    color: #00FF00; /* Dark grey for a softer look than black */
  
    padding: 10px 20px; /* Padding for spacing */
    margin: 20px 0; /* Margin for spacing above and below */

    border-radius: 5px; /* Rounded corners for a softer appearance */
}

h2 {
    font-family: 'Merriweather', serif;
    font-size: 2rem;
    color: #00FF00;

    text-align: center;
    margin-bottom: 2rem;
}
.p_paragraph {
    font-family: 'Merriweather', serif;
    font-size: 1.5rem;
    color: #00ffff;
    text-align: center;
    margin-bottom: 2rem;
}


.article-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;

}

.article-container {
    background-color: rgb(144, 238, 144,.20);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 2em;
    border-radius: 15px; /* Added rounded corners */


}

.article-title {
    font-family: 'Merriweather', serif;
    font-size: 1.5rem;
    color: #00ffff;
    margin-bottom: 0.5rem;
}

.projects-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    padding-top: 1rem;
}

.project-card {
    background-color: rgb(144, 238, 144,.20);

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: center;
}

.project-card img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-bottom: 2px solid #c70039;
}

.quotes-carousel {
    padding: 2rem 1rem;
    background-color: rgb(144, 238, 144,.20);

    text-align: center;
}

.quotes-carousel blockquote {
    font-family: 'Merriweather', serif;
    font-size: 1.5rem;
    color: #00FF00;
    margin: 1rem auto;
    max-width: 700px;
    font-style: italic;
    line-height: 1.5;
}

form {
    max-width: 500px;
    margin: 0 auto;
    padding: 1rem;
    background-color: rgb(144, 238, 144,.20);

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: #333;
}

.form-group input, .form-group textarea {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    margin-top: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button[type="submit"] {
    width: 100%;
    padding: 0.75rem;
    font-size: 1.25rem;
    background-color: #c70039;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button[type="submit"]:hover {
    background-color: #b30030;
}

footer {
    text-align: center;
    padding: 2rem 1rem;
    background-color: rgb(144, 238, 144,.20);

    color: #00ffff;
    font-size: 0.875rem;
    
}

/* Media Queries */

/* Mobile devices (portrait and landscape) */
@media (max-width: 767px) {
    .site-title {
        font-size: 2rem;
    }

    .site-subtitle {
        font-size: 1rem;
    }

    .navbar {
        flex-direction: column;
    }

    .navbar li {
        margin: 0.5rem 0;
    }

    .headline-title {
        font-size: 1.5rem;
    }

    .headline-content {
        font-size: 1rem;
    }

    .article-grid {
        grid-template-columns: 1fr;
    }

    .projects-gallery {
        grid-template-columns: 1fr;
    }

    .quotes-carousel blockquote {
        font-size: 1.25rem;
    }

    form {
        padding: 0.75rem;
    }

    button[type="submit"] {
        font-size: 1rem;
    }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1024px) {
    .site-title {
        font-size: 2.25rem;
    }

    .site-subtitle {
        font-size: 1.125rem;
    }

    .navbar {
        flex-direction: row;
    }

    .headline-title {
        font-size: 1.75rem;
    }

    .headline-content {
        font-size: 1.125rem;
    }

    .article-grid {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .projects-gallery {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .quotes-carousel blockquote {
        font-size: 1.375rem;
    }
}

/* Desktops */
@media (min-width: 1025px) {
    .site-title {
        font-size: 2.5rem;
    }

    .site-subtitle {
        font-size: 1.25rem;
    }

    .headline-title {
        font-size: 2rem;
    }

    .headline-content {
        font-size: 1.25rem;
    }

    .article-grid {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .projects-gallery {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}


.contact-info {
    text-align: left;
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: rgb(144, 238, 144,.20);
}

.contact-info h3 {
    margin-bottom: 15px;
    font-size: 1.5em;
    color: #00ffff;
}

.contact-info p {
    margin: 10px 0;
    font-size: 1em;
    color: #00ffff;
}

.contact-info a {

    color: #00FF00;
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}



.button-Cardss {
    backdrop-filter: blur(50%);
    box-shadow: 0px 0px 30px rgba(227, 228, 237, 0.37);
    border: 1.5px solid rgba(225, 225, 225, 0.18);
    background-color: #0d0d0d; /* Dark background */
    color: #ff007f; /* Neon pink */
    padding: 8px 24px;
    text-align: center;
    font-size: 1em;
    border-radius: 8px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    font-family: 'Courier New', Courier, monospace; /* Hacker-like font */
}

.button-Cardss:hover {
    color: #00ffff; /* Cyan on hover for neon effect */
    box-shadow: 0px 0px 40px rgba(0, 255, 255, 0.5); /* Neon cyan glow */
}