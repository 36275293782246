.fire-ice-text {
    font-size: 3rem;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    background: linear-gradient(90deg, #00ff00, #00aaff, #aa00ff, #00ff00);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    position: relative;
    animation: hacker-glow 5s ease-in-out infinite, scaleText 5s ease-in-out infinite;
    transition: all 0.5s ease;
}

.fire-ice-text::before {
    content: "Vijayvir Singh ";
    display: inline-block;
    color: #00ff00;
}

.fire-ice-text:hover::before {
    content: "ਵਿਜੇਵੀਰ ਸਿੰਘ ";
    font-family: 'Ghwduk5', sans-serif;
    color: #00ff00;
}

/* Multi-color Hacker Glow Animation */
@keyframes hacker-glow {
    0% {
        background-position: 0% 50%;
        text-shadow: 
            0px 0px 10px rgba(0, 255, 0, 0.8),   /* Green glow */
            0px 0px 20px rgba(0, 255, 0, 0.8),
            0px 0px 30px rgba(0, 170, 255, 0.8), /* Cyan glow */
            0px 0px 40px rgba(0, 170, 255, 0.8);
        opacity: 1;
    }
    25% {
        background-position: 25% 50%;
        text-shadow: 
            0px 0px 10px rgba(0, 170, 255, 0.8), /* Cyan glow */
            0px 0px 20px rgba(0, 170, 255, 0.8),
            0px 0px 30px rgba(170, 0, 255, 0.8), /* Purple glow */
            0px 0px 40px rgba(170, 0, 255, 0.8);
        opacity: 0.9;
    }
    50% {
        background-position: 50% 50%;
        text-shadow: 
            0px 0px 10px rgba(170, 0, 255, 0.8), /* Purple glow */
            0px 0px 20px rgba(170, 0, 255, 0.8),
            0px 0px 30px rgba(0, 255, 0, 0.8),   /* Green glow */
            0px 0px 40px rgba(0, 255, 0, 0.8);
        opacity: 0.8;
    }
    75% {
        background-position: 75% 50%;
        text-shadow: 
            0px 0px 10px rgba(170, 0, 255, 0.8), /* Purple glow */
            0px 0px 20px rgba(170, 0, 255, 0.8),
            0px 0px 30px rgba(0, 255, 0, 0.8),   /* Green glow */
            0px 0px 40px rgba(0, 255, 0, 0.8);
        opacity: 0.9;
    }
    100% {
        background-position: 100% 50%;
        text-shadow: 
            0px 0px 10px rgba(0, 255, 0, 0.8),   /* Green glow */
            0px 0px 20px rgba(0, 255, 0, 0.8),
            0px 0px 30px rgba(0, 170, 255, 0.8), /* Cyan glow */
            0px 0px 40px rgba(0, 170, 255, 0.8);
        opacity: 1;
    }
}

/* Scaling Effect */
@keyframes scaleText {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}
