
.wrapper {
    position: relative;
    width: 100%;
    background: rgba(5, 21, 17,.21);
      
  }
  
  .matrix-effect {
    position: relative;

    width: 100%;
    height: 100%;
    background: rgba(5, 21, 17,.21); /* Example: black background */
    z-index: 1; /* Lower z-index to place in background */
    /* Add any effect (like animated matrix effect) here */
  }
  
  .MainIntro {
    position: absolute;
    z-index: 2; /* Higher z-index to place above matrix effect */
    color: #00FF00;/* Example: white text */


    text-align: center;
    padding: 20px;
    top: 0;
    left: 0;
    height: 100%;
    overflow:scroll;
    /* Center content vertically and horizontally */

 
  }

  .content {

  }
  
